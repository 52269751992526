@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

* {
  box-sizing: border-box;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  display: none;
  background: #16161d;
}

.container {
  display: flex;
  flex-direction: column;

  min-height: 100vh;
}

.row {
  display: flex;
  flex-direction: row;
  /* display: grid; */
  /* grid-template-columns: auto auto auto auto auto auto auto auto auto; */
  /* width: 96%; */
}

a {
  text-decoration: none;
}

.row > .card{
  min-width: 180px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 40px;
  font-size: 1em;
  font-weight: 600;
  padding: 20px 30px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.4);
  position: relative;
}

.card[data-live="true"]::after {
  content: "● Live";
  position: absolute;
  right: 10px;
  top: -4px;
  color: #fc3d46;
  font-size: 0.8em;
}

.card[data-upcoming="true"]::after {
  content: "● Next";
  position: absolute;
  right: 10px;
  top: -4px;
  color: #3dfc4b;
  font-weight: 500;
  font-size: 0.8em;
}

.sub {
  color: #b3b7c3;
}

.time a {
  color: #267eee;
}

.popupcont {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(1px);
  place-items: center;
  display: grid;
}

.hidden {
  display: none;
}

.popup {
  width: 720px;
  padding: 60px 0;
  background: #18181f;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup div:nth-child(1) {
  font-weight: 900;
  font-size: 2.4em;
  color: #a5a5d4;
}

.popup div:nth-child(2) {
  display: flex;
  margin-top: 24px;
}

.popup button {
  margin: 0 20px;
  border: none;
  padding: 12px 20px;
  background: #4174f2;
  color: #fefefe;
  font-size: 1.2em;
  border-radius: 6px;
  box-shadow: 0 2px 12px 2px rgba(0, 70, 255, 0.25);
  transition: all 200ms ease-in-out;
  cursor: pointer;
}

.popup button:hover {
  transform: scale(1.125);
}

.setcont {
  position: absolute;
  bottom: 24px;
  right: 24px;
}

.setcont svg {
  color: #666;
  cursor: pointer;
}

a[href="#"] {
  color: #444;
  cursor: default;
}

@media screen and (max-width: 1200px) {
  .quotecont{
    display: none;
  }
}
